import axios from "axios";
import { ApiException } from "@/api/v2/api-exception";
import Cookies from "js-cookie";

export class SignUpDataDTO {
  constructor(
    public petName: string,
    public breedId?: string,
    public dateOfBirth?: string,
    public weight?: number,
    public gender?: string,
    public isNeutered?: string,
    public activityLevel?: string
  ) {}

  static toJson(data: SignUpDataDTO) {
    return {
      pet_name: data.petName,
      breed_id: data.breedId,
      date_of_birth: data.dateOfBirth,
      weight: data.weight,
      gender: data.gender,
      is_neutered: data.isNeutered,
      activity_level: data.activityLevel
    };
  }
}

export class ActivityLevelDTO {
  constructor(
    public activityLevelId: number,
    public identifier: string,
    public nameDefault: string,
    public descriptionDefault: string
  ) {}

  static buildFromJson(json: Record<string, any>): ActivityLevelDTO {
    return new ActivityLevelDTO(
      json.activity_level_id,
      json.identifier,
      json.name_default,
      json.description_default
    );
  }
}

export class BreedDTO {
  constructor(public breedId: number, public name: string) {}

  static buildFromJson(json: Record<string, any>): BreedDTO {
    return new BreedDTO(json.breed_id, json.name);
  }
}

export interface SignUpFormRemoteData {
  activityLevels: Array<ActivityLevelDTO>;
  breeds: Array<BreedDTO>;
  crossBreeds: Array<BreedDTO>;
  minPetAgeDays: number;
}

export class SignUpAPI {
  static baseUrl = "/api/vets/prototype/signup/customer";
  static async createPetSignUpData(customerId: number, data: SignUpDataDTO) {
    try {
      await axios.post(`${this.baseUrl}/pet`, SignUpDataDTO.toJson(data), {
        headers: {
          Authorization: `${Cookies.get("preRegistrationToken")}`
        }
      });
    } catch (error) {
      throw ApiException.fromError(error);
    }
  }

  static async updatePetSignUpData(
    customerId: number,
    selectedPet: number | null,
    data: SignUpDataDTO
  ) {
    try {
      await axios.put(`${this.baseUrl}/pet/${selectedPet}`, SignUpDataDTO.toJson(data), {
        headers: {
          Authorization: `${Cookies.get("preRegistrationToken")}`
        }
      });
    } catch (error) {
      throw ApiException.fromError(error);
    }
  }

  static async createCustomerSignupData(data: any) {
    try {
      const response = await axios.post(`${this.baseUrl}`, data);
      return response.data.resp.token;
    } catch (error) {
      throw ApiException.fromError(error);
    }
  }

  static async getSignUpFormRemoteData(): Promise<SignUpFormRemoteData> {
    const response = await axios.get(`${this.baseUrl}/pet`, {
      headers: {
        Authorization: `${Cookies.get("preRegistrationToken")}`
      }
    });
    const activityLevels = response.data.resp.activity_levels.map((item: Record<string, any>) => {
      return ActivityLevelDTO.buildFromJson(item);
    });
    const breeds = response.data.resp.breeds.map((item: Record<string, any>) => {
      return BreedDTO.buildFromJson(item);
    });

    const crossBreeds = breeds.filter((breed: BreedDTO) =>
      breed.name.toLowerCase().includes("crossbreed")
    );

    return {
      activityLevels: activityLevels,
      breeds: breeds,
      crossBreeds: crossBreeds,
      minPetAgeDays: response.data.resp.min_pet_age_days
    };
  }
}
